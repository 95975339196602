import React from 'react';

class App extends React.Component {
  constructor() {
    super();
    this.data = null;
    this.fetchData();
  }

  fetchData = async () => {
    try {
      const response = await fetch('https://api.binance.com/api/v3/ticker/price?symbol=MATICUSDT');
      const jsonData = await response.json();

      this.data = jsonData;
      this.forceUpdate();
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <div>
        {this.data ? (
          <pre>{/*JSON.stringify(this.data, null, 2)*/
            JSON.stringify(this.data)}</pre>
        ) : (
          <p>Loading data...</p>
        )}
      </div>
    );
  }
}

export default App;
